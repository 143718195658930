import React from "react";
import Layout from "../../layout";
import ContactForm from "../../component/pages/ContactForm/ContactForm";
import { AiFillMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";

const CoctactUs: React.FC = () => {
  return (
    <Layout>
      <div className="flex justify-center">
        <div className="flex md:flex-row flex-col justify-center items-center md:items-start w-11/12 md:m-4 my-4">
          <div className="p-4 md:p-8 text-[#0E0B3D] md:w-1/2">
            <h1 className="text-3xl">Contact Us</h1>
            <h2 className="text-sm pt-3 text-gray-700">Get in touch</h2>
            <br />
            <p className="md:w-4/5">
              We are always looking forward to meet out customers and help them
              navigate our brand easily. If you feel we can help you with
              something, please fill your contact details and one of our
              representatives will be in touch with you shortly.
            </p>
            <br />
            <div className="flex flex-col gap-3 mt-4">
              <span className="flex items-center gap-4">
                <AiFillMail /> info@universalmakers.co.uk
              </span>
              <span className="flex items-center gap-4">
                <BsFillTelephoneFill /> +44 7570 852583
              </span>
            </div>
          </div>
          <div className="md:w-1/2 w-11/12">
            <ContactForm />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CoctactUs;
