import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import MobileDropdown from "../Dropdown/MobileDropdown";
import PreHeader from "./PreHeader";
import UKFlag from "../../../images/Flag_of_the_United_Kingdom.png";
import { SlBag } from "react-icons/sl";
import { CiSearch } from "react-icons/ci";
import { patchesContent, keychainContent } from "../../../utils/navContent";
import { FaArrowRight } from "react-icons/fa6";
import { FiUser } from "react-icons/fi";
import { MdOutlineMenu } from "react-icons/md";
import logo from "../../../images/universalmakers-logo.jpeg";

const Navbar: React.FC = () => {
  const [patch, setPatch] = useState<boolean>(false);
  const [keychain, setKeychain] = useState<boolean>(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [toggleMenu, setToggleMenu] = useState<boolean>(false);

  const handleMouseEnter = (type: string) => {
    if (type === "patch") {
      setPatch(true);
      setKeychain(false);
    } else if (type === "keychain") {
      setKeychain(true);
      setPatch(false);
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  const handleMouseLeave = (type: string) => {
    if (type === "patch") {
      setTimeoutId(
        setTimeout(() => {
          setPatch(false);
          setTimeoutId(null); // Reset timeoutId
        }, 200)
      );
    } else if (type === "keychain") {
      setTimeoutId(
        setTimeout(() => {
          setKeychain(false);
          setTimeoutId(null); // Reset timeoutId
        }, 200)
      );
    }
  };

  const patchesDropdown = [
    {
      name: "PVC Patches",
      link: "/collection/pvc",
    },
    {
      name: "IR Patches",
      link: "/collection/ir",
    },
    {
      name: "Embroidery Patches",
      link: "/collection/embroidery",
    },
    {
      name: "Laser Cut",
      link: "/collection/lasercut",
    },
    {
      name: "Printed",
      link: "/collection/printed",
    },
    {
      name: "Reflective",
      link: "/collection/reflective",
    },
    {
      name: "American Made PVC",
      link: "/collection/american-made-pvc",
    },
  ];

  const keychainDropdown = [
    {
      name: "Ready-to-ship",
      link: "/collection/keychain",
    },
    {
      name: "Custom Made",
      link: "/custom/keychains",
    },
  ];

  return (
    <nav className="flex flex-col justify-center items-center bg-white relative">
      <div className="w-full hidden md:block">
        <PreHeader />
        <div className="p-2 flex justify-between w-full items-start">
          <div className="text-xs flex gap-3 pt-2 pl-2 w-1/3">
            <div className="flex gap-2">
              <img src={UKFlag} alt="UK Flag" width={25} />
              <span>Ship to: United Kingdom</span>
            </div>
            <p>English</p>
            <p>Stores</p>
          </div>
          <div className="w-1/3 flex justify-center items-center">
            <Link to={`/`}>
              <img
                src={logo}
                alt="Universal makers logo"
                width={200}
                height={200}
              />
            </Link>
          </div>
          <div className="flex justify-end items-center gap-3 pt-2 pr-2 w-1/3">
            <div className="flex gap-1 items-center">
              <CiSearch />
              <span className="text-xs">Search</span>
            </div>
            <Link
              to={`/`}
              className="text-xs hover:underline ease-in-out duration-200"
            >
              My Account
            </Link>
            <div>
              <button>
                <Link to={`/cart`}>
                  <SlBag />
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center flex-col">
          <ul className="flex items-center gap-4 py-4 px-4">
            <li
              className="hover:underline cursor-pointer text-[14px] uppercase relative"
              onMouseEnter={() => handleMouseEnter("patch")}
              onMouseLeave={() => handleMouseLeave("patch")}
            >
              Patches
            </li>
            <li
              className="hover:underline cursor-pointer text-[14px] uppercase"
              onMouseEnter={() => handleMouseEnter("keychain")}
              onMouseLeave={() => handleMouseLeave("keychain")}
            >
              Keychains
            </li>
            <li className="hover:underline cursor-pointer text-[14px] uppercase">
              <Link to={`/collection/tshirt`} state={{ title: "T-Shirts" }}>
                T-Shirts
              </Link>
            </li>
            <li className="hover:underline cursor-pointer text-[14px] uppercase">
              <Link to={`/custom/tshirts`}>Custom T-Shirts</Link>
            </li>
            <li className="hover:underline cursor-pointer text-[14px] uppercase">
              <Link to={`/collection/stickers`} state={{ title: "Stickers" }}>
                Stickers
              </Link>
            </li>
            <span className="text-gray-500">|</span>
            <li className="hover:underline cursor-pointer text-[14px] uppercase">
              <Link to={`/contact-us`}>Contact</Link>
            </li>
            <li className="hover:underline cursor-pointer text-[14px] uppercase">
              <Link to={`/reviews`}>Reviews</Link>
            </li>
            <li className="hover:underline cursor-pointer text-[14px] uppercase">
              <Link to={`/faqs`}>FAQs</Link>
            </li>
          </ul>
        </div>
        <div className="w-full border-t border-gray-300 mt-4"></div>
        {patch && (
          <div
            className="p-2 absolute left-0 right-0 top-full z-50 bg-white flex justify-center items-center"
            onMouseEnter={() => clearTimeout(timeoutId!)}
            onMouseLeave={() => setPatch(false)}
          >
            <div className="flex justify-center items-center gap-6 w-1/2 my-4 py-4">
              {patchesContent.map((item, index) => (
                <Link
                  to={item.url}
                  key={index}
                  className="flex flex-col justify-center items-center w-[32]"
                  state={{ title: item.pageTitle }}
                >
                  <div className="w-24 h-24 relative overflow-hidden rounded-full">
                    <div>
                      <img
                        src={item.imgUrl}
                        className="absolute inset-0 w-full h-full object-cover rounded-full transform scale-125"
                        alt={item.name}
                      />
                    </div>
                  </div>
                  <p className="text-sm pt-4 flex gap-3 items-center">
                    <span>{item.name} </span>
                    <span>
                      <FaArrowRight />
                    </span>
                  </p>
                </Link>
              ))}
            </div>
          </div>
        )}
        {keychain && (
          <div
            className="p-2 absolute left-0 right-0 top-full z-50 bg-white flex justify-center items-center"
            onMouseEnter={() => clearTimeout(timeoutId!)}
            onMouseLeave={() => setKeychain(false)}
          >
            <div className="flex justify-center items-center gap-6 w-1/2 my-4 py-4">
              {keychainContent.map((item, index) => (
                <Link
                  to={item.url}
                  key={index}
                  className="flex flex-col justify-center items-center w-[32]"
                  state={{ title: item.pageTitle }}
                >
                  <div className="w-24 h-24 relative overflow-hidden">
                    <div>
                      <img
                        src={item.imgUrl}
                        className="absolute inset-0 w-full h-full"
                        alt={item.name}
                      />
                    </div>
                  </div>
                  <p className="text-sm pt-4 flex gap-3 items-center">
                    <span>{item.name} </span>
                    <span>
                      <FaArrowRight />
                    </span>
                  </p>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="flex justify-between items-center w-full m-2 p-2 md:hidden">
        <div>
          <h1 className="text-3xl uppercase font-semibold font-[imbue] tracking-wide">
            <Link to={`/`}>
              <img
                src={logo}
                alt="Universal makers logo"
                width={100}
                height={100}
              />
            </Link>
          </h1>
        </div>
        <div className="flex items-center gap-2">
          <button>
            <Link to={`/sign-in`}>
              <FiUser className="text-xl" />
            </Link>
          </button>
          <button>
            <Link to={`/cart`}>
              <SlBag className="text-xl" />
            </Link>
          </button>
          {!toggleMenu && (
            <MdOutlineMenu
              className="md:hidden cursor-pointer text-2xl"
              onClick={() => setToggleMenu(true)}
            />
          )}
          {toggleMenu && (
            <AiOutlineClose
              fontSize={28}
              className="md:hidden cursor-pointer"
              onClick={() => setToggleMenu(false)}
            />
          )}
          {toggleMenu && (
            <div
              className="z-10 fixed -top-0 -right-0 p-3 w-full min-h-screen md:hidden list-none
      flex flex-col justify-start items-start bg-white animate-slide-in duration-200 ease-in-out text-black"
            >
              <div
                className="place-self-end py-2 px-3"
                onClick={() => setToggleMenu(false)}
              >
                <AiOutlineClose className="text-lg font-bold" />
              </div>
              <div className="w-full my-4 p-3">
                <ul className="flex flex-col gap-5">
                  <li>
                    <div className="w-full border-t border-gray-300 my-4"></div>
                    <MobileDropdown name="Patches" list={patchesDropdown} />
                    <div className="w-full border-t border-gray-300 my-4"></div>
                  </li>
                  <li>
                    <MobileDropdown name="Keychains" list={keychainDropdown} />
                    <div className="w-full border-t border-gray-300 my-4"></div>
                  </li>
                  <li className="hover:underline cursor-pointer text-[14px] uppercase">
                    <Link
                      to={`/collection/tshirt`}
                      state={{ title: "T-Shirts" }}
                    >
                      T-Shirts
                    </Link>
                    <div className="w-full border-t border-gray-300 my-4"></div>
                  </li>
                  <li className="hover:underline cursor-pointer text-[14px] uppercase">
                    <Link to={`/custom/tshirts`}>Custom T-Shirts</Link>
                    <div className="w-full border-t border-gray-300 my-4"></div>
                  </li>
                  <li className="hover:underline cursor-pointer text-[14px] uppercase">
                    <Link
                      to={`/collection/stickers`}
                      state={{ title: "Stickers" }}
                    >
                      Stickers
                    </Link>
                    <div className="w-full border-t border-gray-300 my-4"></div>
                  </li>
                  <li className="hover:underline cursor-pointer text-[14px] uppercase">
                    <Link to={`/contact-us`}>Contact</Link>
                    <div className="w-full border-t border-gray-300 my-4"></div>
                  </li>
                  <li className="hover:underline cursor-pointer text-[14px] uppercase">
                    <Link to={`/reviews`}>Reviews</Link>
                    <div className="w-full border-t border-gray-300 my-4"></div>
                  </li>
                  <li className="hover:underline cursor-pointer text-[14px] uppercase">
                    <span>Blogs </span>
                    <div className="w-full border-t border-gray-300 my-4"></div>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
