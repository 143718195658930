import React from "react";
import Layout from "../../layout";

const CheckoutFailure: React.FC = () => {
  return (
    <Layout>
      <h1>Payment Unsuccessful</h1>
    </Layout>
  );
};

export default CheckoutFailure;
