import React from 'react';

interface WhatsAppWidgetProps {
  phoneNumber: string;
}

const WhatsAppWidget: React.FC<WhatsAppWidgetProps> = ({ phoneNumber }) => {
  const handleClick = () => {
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}`, '_blank');
  };

  return (
    <div style={widgetStyle} onClick={handleClick}>
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/6b/WhatsApp.svg/1200px-WhatsApp.svg.png"
        alt="WhatsApp Logo"
        style={{ width: '40px', height: '40px' }}
      />
    </div>
  );
};

const widgetStyle: React.CSSProperties = {
  position: 'fixed',
  bottom: '20px',
  right: '20px',
  backgroundColor: '#25d366',
  color: 'white',
  borderRadius: '50%',
  padding: '10px',
  cursor: 'pointer',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  zIndex: 999, // Adjust this value according to your needs
};

export default WhatsAppWidget;
