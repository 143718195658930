import React from 'react'
import Layout from '../../layout'

const CustomPatches: React.FC = () => {
  return (
    <Layout>
        Custom Patches
    </Layout>
  )
}

export default CustomPatches