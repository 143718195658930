import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { FiMinus } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import Reviews from "../../component/pages/Reviews/Reviews";
import reviewsData from "../../utils/reviewsData";
import { useDispatch } from "react-redux";
import { addItem } from "../../state/cart/cartSlice";
import { Link, useParams } from "react-router-dom";
// import { toggleCart } from "../../state/cartToggle/cartToggleSlice";
import LoadingBar from "react-top-loading-bar";
import { IoStarSharp } from "react-icons/io5";
import { SlBag } from "react-icons/sl";
import { TbTruckDelivery } from "react-icons/tb";
import { SiSpringsecurity } from "react-icons/si";
import gpay from "../../images/GooglePay.svg";
import paypal from "../../images/PayPal.svg";
import visa from "../../images/Visa.svg";
import mastercard from "../../images/Mastercard.svg";
import amex from "../../images/Amex.svg";
import apple from "../../images/ApplePay.svg";
import SubscribeEmail from "../../component/pages/SubscribeEmail/SubscribeEmail";
import RecommendedProducts from "../../component/pages/RecommendedProducts/RecommmendedProducts";
import products from "../../utils/products";
import { Product } from "../../utils/productInterface";
import Preloader from "../../component/ui/Preloader/Preloader";

const paymentMethodImages = [gpay, paypal, visa, mastercard, amex, apple];

const ProductPage: React.FC = () => {
  const [loading, setLoading] = useState<number>(0);
  const [quantity, setQuantity] = useState<number>(1);
  const [product, setProduct] = useState<Product | null>(null);
  const [adding, setAdding] = useState<boolean>(false);
  const params = useParams();
  const dispatch = useDispatch();

  console.log(params);

  useEffect(() => {
    // Find the product by productID from the URL params
    const productID = params.id;

    const foundProduct = products.find(
      (product) => product.productID === productID
    );
    if (foundProduct) {
      setProduct(foundProduct);
    } else {
      console.error(`Product with ID ${productID} not found.`);
    }
  }, [params.id]);

  if (!product) {
    // Render loading state or error message
    return <div>Loading...</div>;
  }

  const handleAddToCart = async () => {
    setAdding(true);
    setLoading(30); // Start loading animation

    // Simulate an asynchronous operation (e.g., API request)
    await new Promise((resolve) => setTimeout(resolve, 1000));

    // Dispatch the action to add to cart
    dispatch(
      addItem({
        id: product?.productID,
        name: product?.productName,
        price: product?.productPrice,
        imageUrl: product?.productImage,
        quantity: 1,
      })
    );

    setLoading(100); // Complete loading animation
    setAdding(false);
  };

  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2,
  });

  return (
    <Layout>
      <div className="flex justify-center items-center">
        <div className="md:w-3/4 w-11/12">
          <div className="flex md:flex-row flex-col md:gap-10 gap-6 mt-8">
            <div className="md:w-1/2">
              <img
                src={product.productImage}
                alt="product"
                className="w-full object-cover"
              />
            </div>
            <div className="md:w-1/2 flex flex-col gap-3">
              <h1 className="text-3xl font-bold">{product.productName}</h1>
              <h3 className="text-xl font-semibold">
                {product.productSubtitle}
              </h3>
              <div className="flex gap-1 items-center">
                <IoStarSharp className="text-xs text-yellow-500" />
                <IoStarSharp className="text-xs text-yellow-500" />
                <IoStarSharp className="text-xs text-yellow-500" />
                <IoStarSharp className="text-xs text-yellow-500" />
                <IoStarSharp className="text-xs text-yellow-500" />
                <span className="text-xs">(4.6/5.0 rating)</span>
              </div>
              <span className="text-2xl font-semibold mb-2">
                {formatter.format(product.productPrice)}
              </span>
              <div className="flex gap-5 justify-center items-center border-[1px] border-black p-2 w-[120px]">
                {quantity === 1 ? (
                  <button disabled onClick={() => setQuantity(quantity - 1)}>
                    <FiMinus className="text-lg" />
                  </button>
                ) : (
                  <button onClick={() => setQuantity(quantity - 1)}>
                    <FiMinus className="text-lg" />
                  </button>
                )}
                <span className="font-bold">{quantity}</span>
                <button onClick={() => setQuantity(quantity + 1)}>
                  <IoMdAdd className="text-lg" />
                </button>
              </div>
              <div className="md:flex block gap-4">
                <button
                  className="border-[1px] border-solid border-gray-300 hover:border-black text-sm duration-200 ease-in-out font-semibold w-full my-2 p-3 flex justify-center items-center gap-2"
                  onClick={handleAddToCart}
                >
                  {adding ? (
                    <Preloader />
                  ) : (
                    <>
                      <SlBag />
                      <span>Add to cart</span>
                    </>
                  )}
                </button>
              </div>
              <div className="border-[1px] border-solid border-gray-200 rounded p-6">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-semibold">Payment & Security</h3>
                  <SiSpringsecurity className="text-sky-900 text-xl" />
                </div>
                <div className="flex md:gap-1 gap-0">
                  {paymentMethodImages.map((method, index) => (
                    <img
                      key={index}
                      src={method}
                      className="md:w-[68px] md:h-[48px] w-1/6 h-auto"
                      alt="paymentMethod"
                    />
                  ))}
                </div>
                <p className="text-gray-600 text-xs">
                  Your payment information is processed securely. We do not
                  store credit card details nor have access to your credit card
                  information.
                </p>
              </div>
              <div className="bg-gray-100 rounded py-8 px-4">
                <div className="flex items-center gap-3">
                  <TbTruckDelivery className="text-2xl" />
                  <p className="text-xs font-bold">
                    Please note that delivery times for our products range from
                    7 to 14 days. We appreciate your patience and understanding.
                  </p>
                </div>
                <br />
                <div className="flex items-end gap-3">
                  <p className="text-xs font-semibold">T&C's apply </p>
                  <Link
                    to={"/policies/shipping-policy"}
                    className="text-xs underline hover:text-blue-400"
                  >
                    Learn more
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="my-2 py-2">
            {product.productDescription.length === 0 ? (
              <></>
            ) : (
              <>
                <h1 className="text-2xl font-bold">Product Description</h1>
                <p className="text-md py-2 my-2">
                  {product.productDescription}
                </p>
              </>
            )}
          </div>
          <RecommendedProducts />
          <div className="grid md:grid-cols-3">
            {reviewsData.slice(0, 3).map((review, index) => (
              <Reviews
                key={index}
                imageUrl={review.imageUrl}
                name={review.name}
                date={review.date}
                review={review.review}
              />
            ))}
          </div>
        </div>
      </div>
      <SubscribeEmail />
      <LoadingBar color="#379237" progress={loading} height={7} />
    </Layout>
  );
};

export default ProductPage;
