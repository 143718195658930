import React from "react";
import Layout from "../../layout";

const CheckoutSuccess: React.FC = () => {
  return (
    <Layout>
      <h1>Payment Accepted Successfully</h1>
    </Layout>
  );
};

export default CheckoutSuccess;
