export const customShirt = [
  {
    imageUrl: require("../images/custom-shirts/black.jpg"),
    title: "Black",
    color: "bg-black",
  },
  {
    imageUrl: require("../images/custom-shirts/blue.jpg"),
    title: "Blue",
    color: "bg-blue-700",
  },
  {
    imageUrl: require("../images/custom-shirts/burgundy.jpg"),
    title: "Burgundy",
    color: "bg-red-900",
  },
  {
    imageUrl: require("../images/custom-shirts/grey.jpg"),
    title: "Grey",
    color: "bg-gray-400",
  },
  {
    imageUrl: require("../images/custom-shirts/charcoal.jpg"),
    title: "Charcoal",
    color: "bg-[#4b4848]",
  },
  {
    imageUrl: require("../images/custom-shirts/white.jpg"),
    title: "White",
    color: "bg-white",
  },
];
