import React from "react";
import Layout from "../../layout";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../state/store";
import { Link } from "react-router-dom";
import {
  CartItem,
  clearCart,
  updateQuantity,
} from "../../state/cart/cartSlice";
import { loadStripe } from "@stripe/stripe-js";
// import { loadStripe } from "@stripe/stripe-js";

const Cart: React.FC = () => {
  const cart = useSelector((state: RootState) => state.cart);
  const dispatch = useDispatch();

  console.log(cart);

  const handleUpdateQuantity = (item: CartItem, newQuantity: number) => {
    const updatedItem = { ...item, quantity: newQuantity };
    dispatch(updateQuantity(updatedItem));
  };

  const handleClearCart = () => {
    dispatch(clearCart());
  };

  const handleStripePayment = async () => {
    try {
      const stripe = await loadStripe(
        "pk_test_51OcVjYJ5B345jLFGFVYqGjyF5Cyb7b3eSVqKZAeqYKGWpeSLuLccevSbBXWkiYn0n7JYNSOtxDpZNk7fpKpI3iPH00WyvcMKeJ"
      );

      // Round totalPrice and convert it to cents
      const totalAmount = Math.round(cart.totalPrice * 100);

      const body = {
        products: cart.items,
        totalAmount: totalAmount,
      };

      const headers = { "Content-Type": "application/json" };

      const response = await fetch(
        `https://beltwrestle-api.co.uk/api/v1/stripe-checkout`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(body),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to initiate Stripe session");
      }

      const session = await response.json();

      if (!session || !session.id) {
        throw new Error("Invalid response from server");
      }

      const result = await stripe?.redirectToCheckout({
        sessionId: session.id,
      });

      if (result && result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error("Stripe Payment Error:", error);
    }
  };

  return (
    <Layout>
      <div className="flex flex-col justify-center items-center">
        {cart.items.length === 0 ? (
          <div className="min-h-[400px] flex flex-col justify-center items-center">
            <h1 className="text-2xl">Your cart is empty</h1>
            <br />
            <button className="bg-black p-3 text-white text-sm hover:scale-105 transition-transform duration-200 ease-in-out">
              <Link to={`/`}>Continue Shopping</Link>
            </button>
          </div>
        ) : (
          <div className="flex flex-col w-full md:w-5/6 m-4 p-4">
            <div className="flex justify-between items-center w-full">
              <h1 className="text-2xl">Your Cart</h1>
              <button className="underline">
                <Link to={`/`}>Continue Shopping</Link>
              </button>
            </div>
            <div className="pt-4">
              {cart.items.map((item, index) => (
                <div key={index}>
                  <div className="my-4 flex justify-between">
                    <div className="flex gap-2">
                      <img
                        src={item.imageUrl}
                        alt="product"
                        className="w-[100px] h-[100px]"
                      />
                      <p className="p-2 hover:underline">
                        <Link to={`/products/${item.id}`}>{item.name}</Link>
                      </p>
                    </div>
                    <div className="flex flex-col gap-2">
                      <div className="flex gap-5 justify-center items-center border-[1px] border-black p-2 w-[120px]">
                        <button
                          onClick={() =>
                            handleUpdateQuantity(item, item.quantity - 1)
                          }
                          disabled={item.quantity <= 1}
                        >
                          <span className="text-lg">-</span>
                        </button>
                        <span className="font-bold">{item.quantity}</span>
                        <button
                          onClick={() =>
                            handleUpdateQuantity(item, item.quantity + 1)
                          }
                        >
                          <span className="text-lg">+</span>
                        </button>
                      </div>
                      <p className="font-semibold text-right py-2">
                        {`£`}
                        {item.price}
                        {` GBP`}
                      </p>
                    </div>
                  </div>
                  <div className="w-full border-t border-gray-300 my-2"></div>
                </div>
              ))}
            </div>
            <div className="flex md:justify-end">
              <div className="flex flex-col justify-start items-start md:justify-end md:items-end gap-4">
                <button className="underline" onClick={handleClearCart}>
                  Clear cart
                </button>
                <p>
                  <span>{`Subtotal: `}</span>
                  {cart.totalPrice}
                </p>
                <p className="text-xs font-gray-300">
                  Taxes and shipping calculated at checkout
                </p>
                <button
                  className="bg-black text-center text-white p-2 w-[200px] hover:scale-105 transition-transform duration-200 ease-in-out"
                  onClick={handleStripePayment}
                >
                  Checkout
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Cart;
