import React from "react";

const PreHeader: React.FC = () => {
  return (
    <div className="bg-black flex justify-center items-center w-full">
      <p className="uppercase text-white p-3 text-xs tracking-[1px] font-sans">Discover our latest customized products</p>
    </div>
  );
};

export default PreHeader;
