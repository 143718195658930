import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { useDispatch } from "react-redux";
import { accepted } from "../../../state/cookies/cookieSlice";
import { IoMdClose } from "react-icons/io";

const CookieModal: React.FC = () => {
  const state = useSelector((state: RootState) => state.cookies);
  const dispatch = useDispatch();

  const [active, setActive] = useState<boolean>(state.modalOpen);

  const handleClose = () => {
    dispatch(accepted());
    setActive(false);
  };

  if (!active) {
    return null;
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60">
      <div className="flex flex-col bg-white p-6 m-4 md:w-1/3 w-11/12 rounded-[2px]">
        <div className="flex justify-between">
          <h4 className="text-sm font-bold text-gray-800">
            Your cookie settings
          </h4>
          <button onClick={handleClose}>
            <IoMdClose className="text-gray-500 hover:text-black ease-in-out duration-200" />
          </button>
        </div>
        <br />
        <div>
          <p className="text-xs my-3">
            Welcome to our website! In order to provide you with the best
            possible experience, we use cookies. These small pieces of data are
            stored on your device and help us understand how you interact with
            our site. By continuing to browse, you are giving us your consent to
            utilize these cookies.
          </p>
          <p className="text-xs mb-3">
            If you're curious about how we handle your data and protect your
            privacy, please take a moment to explore our Privacy Policy. It
            provides detailed information on the types of data we collect, how
            we use it, and the measures we take to keep your information secure.
          </p>
          <p className="text-xs mb-3">
            To learn more about our cookie policy, see our {" "}
            <a href="/privacy-policy" className="text-blue-600 underline">
              Privacy Policy
            </a>
          </p>
        </div>
        <br />
        <div className="flex justify-center items-center gap-4">
          <button
            className="bg-gray-900 text-xs font-bold text-white hover:bg-black ease-in-out duration-200 px-8 py-2"
            onClick={handleClose}
          >
            Decline
          </button>
          <button
            className="bg-gray-900 text-xs font-bold text-white hover:bg-black ease-in-out duration-200 px-8 py-2"
            onClick={handleClose}
          >
            Accept
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieModal;
