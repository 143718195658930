import { Product } from "./productInterface";

const products: Product[] = [
  {
    productID: "1pvc",
    productName: "Tactical Pooh Squad",
    productSubtitle:
      "Tactical Eeyore AR15 Callsign SAD - Tactical Pooh Squad - 4 inch PVC Patch",
    productImage: require("../images/products/pvc/product1.webp"),
    productPrice: 10,
    productDescription: `Introducing the newest member of our 'Pooh' squad: the formidable Tactical Eeyore. Standing at 4 inches tall by 3 inches wide, this character is armed with an AR15, equipped with goggles, and proudly wears a 'sad' patch on the shoulder. Ready to bring a distinctive fusion of grit and melancholy to the team, Tactical Eeyore is a force to be reckoned with. Featuring a Hook Fastener backing (loop not included), this imported PVC creation—crafted by Tactical Gear Junkie—embodies both resilience and charm.`,
    productCategory: "pvc",
    reviews: [""],
  },
  {
    productID: "2pvc",
    productName: "Smol PP Club",
    productSubtitle:
      "Smol PP Club - 3.25 inch PVC Patch - It's not small its fun sized!",
    productImage: require("../images/products/pvc/product2.webp"),
    productPrice: 10,
    productDescription: `Size may not always be the determining factor. Embrace the irresistible allure of the 'Smol PP Club' with our daring PVC patch, where we exalt the enchantment found in life's petite pleasures! "A must-have for any lifted truck owner," as proclaimed by every girl on the planet. This PVC patch, measuring roughly 2 inches tall by 3.25 inches wide, is imported and features a hook fastener sewn on the back. Join the club and celebrate the charm of life's smaller joys with us.`,
    productCategory: "pvc",
    reviews: [""],
  },
  {
    productID: "3pvc",
    productName: "Dildo Christmas Wrapping",
    productSubtitle: `Definitely Not a Dildo Christmas Wrapping with Bow 5" PVC Patch - V.2.0 - NSFW`,
    productImage: require("../images/products/pvc/product3.webp"),
    productPrice: 10,
    productDescription: `Definitely NOT a Dildo!" Ensure your holiday spirit stands out with our latest DILDO V.2.0 PVC patch! A must-have for your NSFW collection, this patch is designed to add a touch of cheeky humor to your ensemble. At 5 inches tall and 2.75 inches wide, it's perfectly sized to make a statement without going overboard. Featuring a hook fastener backing, this imported (for now) patch promises to add a playful edge to your attire. Embrace the fun and make a bold statement with our unmistakably unique PVC patch.`,
    productCategory: "pvc",
    reviews: [""],
  },
  {
    productID: "5pvc",
    productName: "American Flag Freedom",
    productSubtitle: `American Flag Freedom Fries - 3.75" PVC Patch`,
    productImage: require("../images/products/pvc/product5.webp"),
    productPrice: 10,
    productDescription: `Introducing Freedom Fries, now immortalized in PVC! This patriotic patch measures 3.75 inches tall by 2.75 inches wide, making it the perfect addition to your collection. With a hook fastener backing and a loop panel included, it's ready to proudly display on your favorite gear. Imported for now, this symbol of freedom is sure to spark conversations wherever you go. So grab your fries and celebrate liberty in style with this iconic patch.`,
    productCategory: "pvc",
    reviews: [""],
  },
  {
    productID: "6pvc",
    productName: "American Flag Lips",
    productSubtitle: `American Flag Lips - 3" PVC Patch`,
    productImage: require("../images/products/pvc/product6.webp"),
    productPrice: 10,
    productDescription: `Celebrate American pride with our American Flag Lips Patch, a bold and badass statement piece that proudly showcases the Stars and Stripes with a unique, punk-rock edge. Measuring 3 inches tall by 3 inches wide, this patch demands attention and commands respect. With a hook fastener backing and loop panel included, it's ready to adorn your favorite gear and make a statement wherever you go. Imported for now, this patch embodies the unyielding spirit of America, serving as a reminder of our nation's resilience and strength.`,
    productCategory: "pvc",
    reviews: [""],
  },
  {
    productID: "7pvc",
    productName: "American Flag Eagle",
    productSubtitle: `The Ultimate expression of Freedom the American Flag Eagle PVC Patch - 4" Tall with Hook Backing`,
    productImage: require("../images/products/pvc/product7.webp"),
    productPrice: 10,
    productDescription: `Embody strength and patriotism with our American Flag Eagle patch, a bold and commanding symbol that demands attention and showcases your pride in style. Measuring 4 inches tall by 3 inches wide, this patch makes a powerful statement wherever it's displayed. With a hook fastener backing and loop panel included, it's ready to adorn your favorite gear and make you stand out. Imported for now, this patch serves as a timeless emblem of American resilience and unity.`,
    productCategory: "pvc",
    reviews: [""],
  },
  {
    productID: "8pvc",
    productName: "Military Police Blue Falcon",
    productSubtitle: `Sketch's World © Military Police Blue Falcon - 4 inch PVC Patch`,
    productImage: require("../images/products/pvc/product8.webp"),
    productPrice: 10,
    productDescription: `Introducing the PVC Sketch's World Themed Patch, an officially licensed accessory that adds a touch of whimsy to any collection. Measuring approximately 4 inches tall, this patch features a hook fastener sewn on the back for easy attachment to your favorite gear. With its playful design and vibrant colors, it's sure to stand out and spark conversation wherever you go. Celebrate the imaginative world of Sketch with this delightful patch.`,
    productCategory: "pvc",
    reviews: [""],
  },
  {
    productID: "9pvc",
    productName: "US Flag - Rounded",
    productSubtitle: `US Flag - Rounded Corners - 2"x3" PVC Patch (USA Made)`,
    productImage: require("../images/products/pvc/product9.webp"),
    productPrice: 10,
    productDescription: `Introducing our USA Flag PVC Patch, proudly crafted in America. Measuring 2 inches tall by 3 inches wide, this patch is a symbol of patriotism and pride. With a hook fastener sewn on the back, it's easy to attach to your favorite gear and showcase your American spirit. Made with quality craftsmanship and attention to detail, this patch is a timeless addition to any collection. Join us in celebrating the red, white, and blue with this iconic emblem of freedom.`,
    productCategory: "pvc",
    reviews: [""],
  },
  {
    productID: "10pvc",
    productName: "Space Force",
    productSubtitle: `Space Force - Glow in the Dark - 4x2.5 inch PVC Patch`,
    productImage: require("../images/products/pvc/product10.jpg"),
    productPrice: 10,
    productDescription: `Introducing our enhanced Space Force PVC Patch, now featuring a glow-in-the-dark backing for added flair. Measuring approximately 4 inches tall by 2.5 inches wide, this patch showcases the adventurous spirit of the Space Force. Imported for quality, it boasts a hook fastener sewn on the back for easy attachment to your gear. Additionally, it includes a soft loop fastener side for versatile display options. Illuminate your collection with this unique patch that pays tribute to the cosmos and the brave individuals who explore it.`,
    productCategory: "pvc",
    reviews: [""],
  },
  {
    productID: "1-embroided",
    productName: "Gen Z Style Embroidered Patch",
    productSubtitle: `Rizz 'Em With The 'Tism Playful Unique Gen Z Style Embroidered Patch - 2x3 inches`,
    productImage: require("../images/products/embroided/product1.webp"),
    productPrice: 10,
    productDescription: `Inject some personality into your style with the Rizz 'Em With The 'Tism 2x3 Patch! Let your playful side shine with this quirky accessory that shows you don't take yourself too seriously. Measuring 2 inches high by 3 inches wide, it's perfect for adding a touch of humor to any clothing or bag. With its hook fastener backing, it's easy to attach to hats, bags, and jackets with loop fastener panels. Crafted with care in the USA by Tactical Gear Junkie, this patch is not only a fun addition to your wardrobe but also a testament to your love of wordplay.`,
    productCategory: "embroided",
    reviews: [""],
  },
  {
    productID: "2-embroided",
    productName: "Heartbreaker, Tattoo Style",
    productSubtitle: `Heartbreaker, Tattoo Style Fully Embroidered Patch - 4" inch`,
    productImage: require("../images/products/embroided/product2.webp"),
    productPrice: 10,
    productDescription: `Unveiling the "Heart Breaker" patch from Universal Makers, a captivating emblem that encapsulates the tumultuous journey of love and the resilience born from romantic wounds. Approximately 3.5 inches wide and 4 inches long, this patch features a pierced heart with a bold arrow through its center, symbolizing both passion and pain. With its hook fastener backing, it effortlessly attaches to hats, bags, and jackets with loop fastener panels, making it a versatile addition to your accessories. Designed and crafted with care in the UK by Universal Makers, this patch serves as a poignant reminder of the complexities of the human heart. Please note that it does not include a matching loop fastener piece.`,
    productCategory: "embroided",
    reviews: [""],
  },
  {
    productID: "3-embroided",
    productName: "Tactical Cupid Cherub",
    productSubtitle: `Tactical Cupid Cherub with AR-15 and Glow in the Dark Night Vision - 4.25" inch Patch`,
    productImage: require("../images/products/embroided/product3.webp"),
    productPrice: 10,
    productDescription: `Introducing the Tactical Cherub Patch by Universal Makers, a captivating blend of celestial strength and tactical flair. Measuring approximately 4.25 inches tall by 4 inches wide, this dynamic patch features a winged cherub adorned with combat gear, adding a distinctive edge to any clothing or gear. With its hook fastener backing, it effortlessly attaches to hats, bags, and jackets with loop fastener panels, making it a versatile accessory for showcasing your fierce and quirky side. Crafted with precision in the UK by Universal Makers, this patch embodies quality craftsmanship and imaginative design. Please note, it does not include a matching loop fastener piece.`,
    productCategory: "embroided",
    reviews: [""],
  },
  {
    productID: "4-embroided",
    productName: "Texas Border Wall",
    productSubtitle: `Come And Take It Texas Border Wall Barbed Wire - 2x3 Patch`,
    productImage: require("../images/products/embroided/prodcut4.webp"),
    productPrice: 10,
    productDescription: `Display your support for Texas with our Come and Take it Barbed Wire Flag Patch from Universal Makers. Available in both white and OCP colors, this patch is a bold statement of pride and resilience. Measuring 2 inches high by 3 inches wide, it's the perfect size to adorn hats, bags, and jackets with loop fastener panels. Crafted with care in the USA by Universal Makers, this patch includes a hook fastener backing for easy attachment and is a testament to quality and craftsmanship. Show your Texas spirit with this iconic emblem wherever you go.`,
    productCategory: "embroided",
    reviews: [""],
  },
  {
    productID: "5-embroided",
    productName: "Fuck Like The Government",
    productSubtitle: `I'm Not Much to Look At But I Fuck Like The Government - 2x3 Patch`,
    productImage: require("../images/products/embroided/product5.webp"),
    productPrice: 10,
    productDescription: `Inject some humor into your gear with our 'I'm Not Much to Look At But I Fuck Like The Government' patch from Universal Makers. Measuring 2 inches high by 3 inches wide, this patch is bound to elicit laughter while serving as a reminder that life can sometimes leave you 'screwed hard' regardless. With its hook fastener backing, it's perfect for hats, bags, and jackets with loop fastener panels. Crafted with pride in the USA by Universal Makers, please note that camouflage pattern may vary depending on the placement of the patch on the fabric. Add a touch of irreverence to your ensemble with this tongue-in-cheek accessory.`,
    productCategory: "embroided",
    reviews: [""],
  },
  {
    productID: "6-embroided",
    productName: "Big Heart Patch NSFW",
    productSubtitle: `February 2024 POTM - Big Heart Patch Only - Patch OCP THEME`,
    productImage: require("../images/products/embroided/product6.webp"),
    productPrice: 10,
    productDescription: `Add a touch of playful and quirky flair to your attire with our Conversation Style SMOL PP - Big Heart Patch NSFW - 2.5 inches from Universal Makers. Measuring 2 1/4 inches wide by 2 1/2 inches tall, this patch is sure to turn heads and spark some entertaining conversations (and perhaps even a few blushing faces). With its hook fastener backing, it's ideal for accessorizing hats, bags, and jackets with loop fastener panels. Crafted with pride in the USA by Universal Makers, please note that this patch does not include a matching loop fastener piece. Embrace the fun and let your personality shine with this unique accessory.`,
    productCategory: "embroided",
    reviews: [""],
  },
  {
    productID: "7-embroided",
    productName: "Heart Shots Only",
    productSubtitle: `February 2024 POTM - Heart Shots Only - Cupid with AR15 Rifle 4" Patch OCP THEME`,
    productImage: require("../images/products/embroided/product7.webp"),
    productPrice: 10,
    productDescription: `Prepare to spread love (and a little lead) with our February 2024 POTM - Heart Shots Only patch from Universal Makers! Measuring approximately 4 inches tall by 4 inches wide, this patch features an unconventional cupid armed with an AR15 rifle, adding a playful twist to your collection. Ideal for military enthusiasts and fans of wordplay alike, this patch boasts a hook fastener backing for easy attachment. Crafted with care in the USA by Universal Makers, please note that it does not include a matching loop fastener piece. Embrace the fun and add some humor to your ensemble with this unique accessory.`,
    productCategory: "embroided",
    reviews: [""],
  },
  {
    productID: "8-embroided",
    productName: "Skeleton Heart Patch",
    productSubtitle: `Skeleton Heart - Love you to Death - 4 inch Patch`,
    productImage: require("../images/products/embroided/product8.webp"),
    productPrice: 10,
    productDescription: `Embrace the paradox of eternal love and mortality with our "Love You to Death" skeleton heart patch from Universal Makers. This intricately designed patch features two skeleton heads intricately entwined within a heart-shaped canvas, capturing the haunting allure of everlasting love. Measuring approximately 4 inches wide by 3 1/2 inches tall, it's perfect for accessorizing hats, bags, and jackets with loop fastener panels. Crafted with pride in the USA by Universal Makers, this patch includes a hook fastener backing for easy attachment. Please note that it does not include a matching loop fastener piece. Add a touch of romance and intrigue to your ensemble with this captivating accessory.`,
    productCategory: "embroided",
    reviews: [""],
  },
  {
    productID: "9-embroided",
    productName: "Snowman Tactical Yeti",
    productSubtitle: `Abominable Snowman Tactical Yeti With AR-15 3.75" Fully Embroidered Patch`,
    productImage: require("../images/products/embroided/product9.webp"),
    productPrice: 10,
    productDescription: `Elevate your mountain warfare gear with our Abominable Alpine Defender patch from Universal Makers. This rugged yeti-inspired patch, fully embroidered for maximum durability and style, adds a touch of untamed charisma to your hats, bags, and jackets. Crafted from high-quality materials, this American-made masterpiece measures 3.75 inches high by 2.5 inches wide and includes a hook fastener backing for easy attachment. Conquer the peaks in both military and mythical flair with this must-have accessory. Please note that it does not include a matching loop fastener piece. Unleash your adventurous spirit with the Abominable Alpine Defender patch.`,
    productCategory: "embroided",
    reviews: [""],
  },
  {
    productID: "10-embroided",
    productName: `Aquariass - 4.5" Patch`,
    productSubtitle: ``,
    productImage: require("../images/products/embroided/product10.webp"),
    productPrice: 10,
    productDescription: `Celebrate your Aquarian spirit with our tribute patch from Universal Makers! Whether you embody the traits of independence, innovation, intellect, and humanitarianism, or you're just embracing your inner rebel, this patch is for you. Measuring 3.5 inches high by 4.5 inches wide, it's perfect for showcasing on hats, bags, and jackets with loop fastener panels. Crafted with pride in the USA by Universal Makers, this patch includes a hook fastener backing for easy attachment. Please note that it does not include a matching loop fastener piece. Embrace your Aquarian essence with this bold and playful accessory. ♒`,
    productCategory: "embroided",
    reviews: [""],
  },
  {
    productID: "1-ir-patch",
    productName: `Custom Double Sided Laser`,
    productSubtitle: `Custom Double Sided Laser Cut Call Sign - 2x4 CORDURA® Patch - IR (Infrared)`,
    productImage: require("../images/products/ir/product1.webp"),
    productPrice: 35.99,
    productDescription: `Introducing our Double-Sided Custom Call Sign Patch from Universal Makers, offering the versatility of two different text illumination types in one patch. With a simple flip, switch between Infrared, Reflective, Glow in the Dark, or Non-Reflective text options to suit your needs. These patches are designed to securely attach to any loop panel equipped gear, featuring a hook fastener border on both sides of the durable laser-cut CORDURA® patch. Each patch is made to your specifications, accommodating up to 8 characters/numbers/symbols in stencil font. Measuring 2 inches tall by 4 inches wide, they are available in your choice of CORDURA® fabric color. Please note that the IR (Infrared) versions are subject to export control under the International Traffic in Arms Regulations (ITAR) and cannot be shipped outside of the United States. For bulk orders and additional customization options, please contact us. Explore the How to Customize tab before ordering. Elevate your gear with these customizable and adaptable call sign patches.`,
    productCategory: "ir",
    reviews: [""],
  },
  {
    productID: "2-ir-patch",
    productName: `III Woodland Digital Patch Set`,
    productSubtitle: `USN Laser Cut Reverse Flag & Don't Tread On Me - NWU Type III Woodland Digital Patch Set`,
    productImage: require("../images/products/ir/product2.webp"),
    productPrice: 29.99,
    productDescription: `Introducing our 2 1/8" tall by 4" wide patches tailored specifically for USN NWU Type III Woodland Digital Uniforms, meticulously crafted and certified for official wear per USN 3603.4a. Available in both Non-Tactical (Fabric) and Tactical IR (Infrared) Light Reflective variants, these patches are designed to meet your uniform needs with precision and functionality. Each patch is backed with hook fastener for easy attachment, ensuring secure placement on your uniform. Proudly made in the USA, these patches are a testament to quality and craftsmanship. Please note that matching loop fastener pieces are not included. Elevate your uniform with these exceptional patches, perfectly suited for official wear.`,
    productCategory: "ir",
    reviews: [""],
  },
  {
    productID: "3-ir-patch",
    productName: `IR (Infrared) Blood Type`,
    productSubtitle: `IR (Infrared) Blood Type - 2x2 Patch`,
    productImage: require("../images/products/ir/product3.webp"),
    productPrice: 10,
    productDescription: `*Attention: Milspec/Solas IR patches cannot be shipped outside of the US.* Our Infrared patches are uniquely designed to be visibly reflective through illuminating night vision gear, enhancing your visibility in low-light conditions. Measuring 2 inches wide by 2 inches tall, these patches include a hook fastener backing for easy attachment to hats, bags, and jackets with loop fastener panels. Crafted with precision in the USA, these patches are a testament to quality and innovation. Elevate your visibility and safety with our Infrared patches, perfect for enhancing your gear in low-light environments.`,
    productCategory: "ir",
    reviews: [""],
  },
  {
    productID: "1-laser-patch",
    productName: `Alphabet Letters Patch`,
    productSubtitle: `Alphabet Letters Patch Pack - CORDURA`,
    productImage: require("../images/products/laser/product1.webp"),
    productPrice: 12.00,
    productDescription: `Introducing our Laser Cut Individual Alphabet Letter Patches from Universal Makers! Each pack includes fifty-two 3/4" individual letters, meticulously crafted to enhance your gear with personalized flair. Backed with hook fastener for easy attachment, these patches are .75 inches tall, making them perfect for a variety of applications. Crafted with precision on CORDURA® fabric in your choice of color, please note that camouflage patterns may vary depending on the placement of the patch on the fabric. Each pack contains 2 of each letter, providing you with ample options for customization. Elevate your gear with our Alphabet Letter Packs and express yourself with style and creativity.`,
    productCategory: "laser",
    reviews: [""],
  },
  {
    productID: "2-laser-patch",
    productName: `EMT Star Of Life`,
    productSubtitle: `EMT Star Of Life Laser Cut Reflective -3 inch CORDURA® Patch`,
    productImage: require("../images/products/laser/product2.webp"),
    productPrice: 16.99,
    productDescription: `Introducing our Laser Cut EMT Star of Life Patch, expertly crafted on Blue CORDURA® fabric for durability and style. Choose from Reflective, Non-Reflective, or Glow-in-the-Dark graphics to suit your preferences and needs. Backed with hook fastener for easy attachment, this patch measures 3 inches in diameter, making it perfect for hats, bags, and jackets. Contact us for bulk orders and additional customization options, and elevate your gear with this essential emblem of emergency medical services.`,
    productCategory: "laser",
    reviews: [""],
  },
  {
    productID: "3-laser-patch",
    productName: `Range Safety Officer`,
    productSubtitle: `RSO (Range Safety Officer) Laser Cut Reflective - 2x4 CORDURA® Patch`,
    productImage: require("../images/products/laser/product3.webp"),
    productPrice: 16.99,
    productDescription: `Introducing our Red Laser Cut RSO Patch featuring vibrant yellow honeycomb reflective text for maximum visibility. Backed with hook fastener for effortless attachment, this patch measures 2 inches tall by 4 inches wide, making it ideal for hats, bags, and jackets. Crafted on durable CORDURA® fabric in striking red, this patch is both stylish and functional. Contact us for bulk orders and additional customization options to personalize your gear. Please note that matching loop fastener pieces are not included. Upgrade your gear with this eye-catching emblem today!`,
    productCategory: "laser",
    reviews: [""],
  },
];

export default products;
