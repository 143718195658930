export interface NavItem {
  imgUrl: string;
  name: string;
  url: string;
  pageTitle: string;
}

export const patchesContent: NavItem[] = [
  {
    imgUrl: require("../images/nav-images/pvc.jpg"),
    name: "PVC",
    url: "/collection/pvc",
    pageTitle: "PVC Patches",
  },
  {
    imgUrl: require("../images/nav-images/ir.avif"),
    name: "IR",
    url: "/collection/ir",
    pageTitle: "IR Patches",
  },
  {
    imgUrl: require("../images/nav-images/laser.webp"),
    name: "Laser",
    url: "/collection/laser",
    pageTitle: "Laser Patches",
  },
  {
    imgUrl: require("../images/nav-images/printed.webp"),
    name: "Printed",
    url: "/collection/printed",
    pageTitle: "Printed Patches",
  },
  {
    imgUrl: require("../images/nav-images/reflective.jpg"),
    name: "Reflective",
    url: "/collection/reflective",
    pageTitle: "Reflective Patches",
  },
  {
    imgUrl: require("../images/nav-images/embrioded.webp"),
    name: "Embrioded",
    url: "/collection/embrioded",
    pageTitle: "Embrioded Patches",
  },
];

export const keychainContent: NavItem[] = [
  {
    imgUrl: require("../images/nav-images/keychain-ready.webp"),
    name: "Ready-to-ship",
    url: "/collection/keychains",
    pageTitle: "Keychains",
  },
  {
    imgUrl: require("../images/nav-images/keychain-custom.webp"),
    name: "Custom Made",
    url: "/custom/keychains",
    pageTitle: "Custom Keychains",
  },
];

