import React from "react";
import Layout from "../../layout";
import { Link } from "react-router-dom";

const NotFound: React.FC = () => {
  return (
    <Layout>
      <div className="flex flex-col justify-center items-center">
        <div className="w-11/12 flex flex-col justify-center items-center">
          <h1 className="md:text-8xl text-5xl font-bold text-green-600 p-6 m-3 text-center">
            404 - Something doesn't seem right.
          </h1>
          <p className="text-xl font-semibold p-6 m-3 text-center">
            The page you're looking for seems to be either missing or under
            construction, please try again later.
          </p>
          <Link to={`/`}>
            <button className="px-5 py-3 bg-blue-800 text-white rounded font-bold">
              Back to home
            </button>
          </Link>
          <br />
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
