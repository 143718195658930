import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addItem } from "../../state/cart/cartSlice";
import Layout from "../../layout";
import { customShirt } from "../../utils/customShirts";
import { Link } from "react-router-dom";
import { SlBag } from "react-icons/sl";
import { TbTruckDelivery } from "react-icons/tb";
import { SiSpringsecurity } from "react-icons/si";
import gpay from "../../images/GooglePay.svg";
import paypal from "../../images/PayPal.svg";
import visa from "../../images/Visa.svg";
import mastercard from "../../images/Mastercard.svg";
import amex from "../../images/Amex.svg";
import apple from "../../images/ApplePay.svg";
import { IoStarSharp } from "react-icons/io5";
import LoadingBar from "react-top-loading-bar";
import Preloader from "../../component/ui/Preloader/Preloader";

const CustomShirts: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<number>(0);
  const [adding, setAdding] = useState<boolean>(false);
  const [selectedOptions, setSelectedOptions] = useState({
    color: "Black",
    size: "M",
    quantity: 1,
  });

  const handleOptionChange = (key: string, value: string | number) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  // Format price
  const formatter = new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    minimumFractionDigits: 2,
  });
  const price = 10;

  const handleAddToCart = async () => {
    setAdding(true);
    setLoading(30); // Start loading animation

    // Simulate an asynchronous operation (e.g., API request)
    await new Promise((resolve) => setTimeout(resolve, 1000));

    const { color, size, quantity } = selectedOptions;
    const newItem = {
      id: color, // You can use a unique identifier for each item
      name: `Custom Shirt - ${color} - (${size})`, // Example name
      price,
      imageUrl:
        customShirt.find(({ title }) => title === color)?.imageUrl || "",
      quantity,
    };

    // Dispatch the action to add to cart
    dispatch(addItem(newItem));

    setLoading(100);
    setAdding(false);
  };

  const paymentMethodImages = [gpay, paypal, visa, mastercard, amex, apple];

  return (
    <Layout>
      <div className="flex flex-col justify-center items-center">
        <div className="flex flex-col md:flex-row md:w-3/4 w-11/12 py-8">
          <div className="flex-1 m-4 p-4">
            <img
              src={
                customShirt.find(({ title }) => title === selectedOptions.color)
                  ?.imageUrl
              }
              alt={selectedOptions.color}
              className="mx-auto max-w-full h-auto"
            />
          </div>
          <div className="flex-1 m-4 p-4">
            <h1 className="text-3xl py-4 font-bold">
              Personalised Budget T-Shirt Printing
            </h1>
            <div className="flex gap-1 items-center my-3">
              <IoStarSharp className="text-xs text-yellow-500" />
              <IoStarSharp className="text-xs text-yellow-500" />
              <IoStarSharp className="text-xs text-yellow-500" />
              <IoStarSharp className="text-xs text-yellow-500" />
              <IoStarSharp className="text-xs text-yellow-500" />
              <span className="text-xs">(4.6/5.0 rating)</span>
            </div>
            <div className="mb-8">
              <p className="text-2xl font-semibold mb-2">
                {formatter.format(price)}
              </p>
              <p className="text-gray-500">Price per item</p>
            </div>
            <h2 className="mb-4">Select a color:</h2>
            <div className="flex justify-center flex-wrap bg-gray-300 p-2">
              {customShirt.map(({ title, color }) => (
                <button
                  key={title}
                  className={`w-16 h-16 m-1 ${color}`}
                  onClick={() => handleOptionChange("color", title)}
                >
                  {/* No text inside the button */}
                </button>
              ))}
            </div>
            <div className="my-4">
              <label htmlFor="size" className="mr-2">
                Size:
              </label>
              <select
                id="size"
                value={selectedOptions.size}
                onChange={(e) => handleOptionChange("size", e.target.value)}
                className="px-2 py-1 border border-gray-300"
              >
                <option value="S">S</option>
                <option value="M">M</option>
                <option value="L">L</option>
                <option value="XL">XL</option>
              </select>
            </div>
            <div className="my-4">
              <label htmlFor="quantity" className="mr-2">
                Quantity:
              </label>
              <input
                type="number"
                id="quantity"
                value={selectedOptions.quantity}
                onChange={(e) =>
                  handleOptionChange("quantity", parseInt(e.target.value))
                }
                className="px-2 py-1 border border-gray-300"
              />
            </div>
            <button
              className="border-[1px] border-solid border-gray-300 hover:border-black text-sm duration-200 ease-in-out font-semibold w-full my-2 p-3 flex justify-center items-center gap-2"
              onClick={handleAddToCart}
            >
              {adding ? (
                <Preloader />
              ) : (
                <>
                  <SlBag />
                  <span>Add to cart</span>
                </>
              )}
            </button>
            <div className="border-[1px] border-solid border-gray-100 p-6 my-3">
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold">Payment & Security</h3>
                <SiSpringsecurity className="text-sky-900 text-xl" />
              </div>
              <div className="flex md:gap-1 gap-0">
                {paymentMethodImages.map((method, index) => (
                  <img
                    key={index}
                    src={method}
                    className="md:w-[68px] md:h-[48px] w-1/6 h-auto"
                    alt="paymentMethod"
                  />
                ))}
              </div>
              <p className="text-gray-600 text-xs">
                Your payment information is processed securely. We do not store
                credit card details nor have access to your credit card
                information.
              </p>
            </div>
            <div className="bg-gray-100 py-8 px-4">
              <div className="flex items-center gap-3">
                <TbTruckDelivery className="text-2xl" />
                <p className="text-xs font-bold">
                  Please note that delivery times for our products range from 7
                  to 14 days. We appreciate your patience and understanding.
                </p>
              </div>
              <br />
              <div className="flex items-end gap-3">
                <p className="text-xs font-semibold">T&C's apply </p>
                <Link
                  to={"/policies/shipping-policy"}
                  className="text-xs underline hover:text-blue-400"
                >
                  Learn more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingBar color="#379237" progress={loading} height={7} />
    </Layout>
  );
};

export default CustomShirts;
