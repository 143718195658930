import React, { useState, useEffect, ChangeEvent } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";

interface Country {
  name: {
    common: string;
  };
}

interface Address {
  streetAddress: string;
  city: string;
  state: string;
  country: string;
  postcode: string;
}

export interface Customer {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  billingAddress: Address;
  shippingAddress: Address;
  products: { productId: string; quantity: number };
  amount: number;
  paymentMethod: string;
  notes: string;
}

const OrderForm: React.FC = () => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [isChecked, setIsChecked] = useState<boolean>(true);
  // const [data, setData] = useState<Customer>();

  useEffect(() => {
    axios
      .get<Country[]>("https://restcountries.com/v3.1/all")
      .then((res: AxiosResponse<Country[]>) => setCountries(res.data))
      .catch((err: AxiosError) => console.log(err));
  }, []);

  const handleCountryChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedCountry(event.target.value);
  };

  const handleBillingAddress = () => {
    setIsChecked(!isChecked);
  };

  const handlePlaceOrder = () => {};

  return (
    <form className="flex flex-col m-2 p-3" onSubmit={handlePlaceOrder}>
      <label className="text-md font-semibold">Contact</label>
      <input
        type="email"
        placeholder="johndoe@mail.com"
        className="p-3 border-[2px] text-sm border-solid border-gray-100 focus:border-blue-400 rounded my-2 focus:outline-none"
      />
      <input
        type="number"
        placeholder="+44 7XXX XXX XXX"
        className="p-3 border-[2px] text-sm border-solid border-gray-100 focus:border-blue-400 rounded my-2 focus:outline-none"
      />
      <br />
      <h1 className="text-md font-semibold">Delivery</h1>
      <div className="grid grid-cols-2 gap-3">
        <div className="flex flex-col w-full">
          <input
            type="text"
            placeholder="John"
            className="p-3 border-[2px] text-sm border-solid border-gray-100 focus:border-blue-400 rounded my-2 focus:outline-none"
          />
        </div>
        <div className="flex flex-col w-full">
          <input
            type="text"
            placeholder="Doe"
            className="p-3 border-[2px] text-sm border-solid border-gray-100 focus:border-blue-400 rounded my-2 focus:outline-none"
          />
        </div>
      </div>
      <input
        type="text"
        placeholder="Street Address"
        className="p-3 border-[2px] text-sm border-solid border-gray-100 focus:border-blue-400 rounded my-2 focus:outline-none"
      />
      <div className="grid grid-cols-3 gap-3">
        <div className="flex flex-col w-full">
          <input
            type="text"
            placeholder="City"
            className="p-3 border-[2px] text-sm border-solid border-gray-100 focus:border-blue-400 rounded my-2 focus:outline-none"
          />
        </div>
        <div className="flex flex-col w-full">
          <input
            type="text"
            placeholder="State"
            className="p-3 border-[2px] text-sm border-solid border-gray-100 focus:border-blue-400 rounded my-2 focus:outline-none"
          />
        </div>
        <div className="flex flex-col w-full">
          <input
            type="text"
            placeholder="Post Code"
            className="p-3 border-[2px] text-sm border-solid border-gray-100 focus:border-blue-400 rounded my-2 focus:outline-none"
          />
        </div>
      </div>
      <select
        className="p-3 border-[2px] border-solid border-gray-100 focus:border-blue-400 focus:outline-none text-sm rounded my-2"
        value={selectedCountry}
        onChange={handleCountryChange}
      >
        {countries.map((country, index) => (
          <option key={index} value={country.name.common}>
            {country.name.common}
          </option>
        ))}
      </select>
      <label className="flex items-center text-gray-500 text-sm gap-2 my-2 py-3">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleBillingAddress}
        />
        Billing address is same as the delivery address
      </label>
      <br />
      {!isChecked && (
        <>
          <h1 className="text-md font-semibold">Billing Address</h1>
          <input
            type="text"
            placeholder="Street Address"
            className="p-3 border-[2px] text-sm border-solid border-gray-100 focus:border-blue-400 rounded my-2 focus:outline-none"
          />
          <div className="grid grid-cols-3 gap-3">
            <div className="flex flex-col w-full">
              <input
                type="text"
                placeholder="City"
                className="p-3 border-[2px] text-sm border-solid border-gray-100 focus:border-blue-400 rounded my-2 focus:outline-none"
              />
            </div>
            <div className="flex flex-col w-full">
              <input
                type="text"
                placeholder="State"
                className="p-3 border-[2px] text-sm border-solid border-gray-100 focus:border-blue-400 rounded my-2 focus:outline-none"
              />
            </div>
            <div className="flex flex-col w-full">
              <input
                type="text"
                placeholder="Post Code"
                className="p-3 border-[2px] text-sm border-solid border-gray-100 focus:border-blue-400 rounded my-2 focus:outline-none"
              />
            </div>
          </div>
          <select
            className="p-3 border-[2px] border-solid border-gray-100 focus:border-blue-400 focus:outline-none text-sm rounded my-2"
            value={selectedCountry}
            onChange={handleCountryChange}
          >
            {countries.map((country, index) => (
              <option key={index} value={country.name.common}>
                {country.name.common}
              </option>
            ))}
          </select>
        </>
      )}
      <button
        className="w-full bg-blue-500 hover:bg-blue-600 duration-200 ease-in-out text-sm p-3 text-white font-semibold rounded"
        type="submit"
      >
        Pay now
      </button>
    </form>
  );
};

export default OrderForm;
