import React from "react";
import "./Banner.css";
import { Link } from "react-router-dom";

const Banner: React.FC = () => {
  return (
    <div className="slide-in background text-white min-h-screen flex flex-col items-center justify-center">
      <button className="bg-gray-900 hover:bg-black ease-in-out duration-200 text-white hover:scale-105 transform-transition px-5 py-3 font-semibold text-xs">
        <Link to={`/shop`}>Shop Now</Link>
      </button>
    </div>
  );
};

export default Banner;
