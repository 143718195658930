import React from "react";
import logo from "../../images/universalmakers-logo.jpeg";
import { SlBag } from "react-icons/sl";
import { Link } from "react-router-dom";
import OrderForm from "../../component/pages/OrderForm/OrderForm";

const PlaceOrder: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="md:w-3/4 w-11/12 flex justify-between items-center p-3 m-2">
        <img src={logo} alt="logo" width={100} height={100} />
        <Link to={`/cart`}>
          <SlBag className="text-xl" />
        </Link>
      </div>
      <div className="w-full border-t border-gray-300"></div>
      <div className="md:w-3/4 w-11/12 flex">
        <div className="w-1/2">
          <OrderForm />
        </div>
        <div className="w-1/2 bg-gray-200">text</div>
      </div>
    </div>
  );
};

export default PlaceOrder;
