import React from "react";
import Layout from "../../layout";
import Banner from "../../component/pages/Banner/Banner";
import SubscribeEmail from "../../component/pages/SubscribeEmail/SubscribeEmail";
import CookieModal from "../../component/pages/CookieModal/CookieModal";
import FeaturedProducts from "../../component/pages/EliteSection/EliteSection";
import CustomActionCards from "../../component/pages/CustomActionCards/CustomActionCards";
import PostBanner from "../../component/pages/PostBanner/PostBanner";
// import Categories from "../../component/pages/Categories/Categories";

const Home: React.FC = () => {
  return (
    <Layout>
      <Banner />
      <PostBanner />
      <FeaturedProducts />
      <CustomActionCards />
      {/* <Categories /> */}
      <SubscribeEmail />
      <CookieModal />
    </Layout>
  );
};

export default Home;
